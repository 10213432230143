var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_box flex-row"},[_c('div',{staticClass:"block_1 flex-col"}),_c('span',{staticClass:"top_text_1"},[_vm._v("鳞鲤科技")]),_vm._l((_vm.texts),function(text,index){return _c('span',{key:index,ref:'menu-' + index,refInFor:true,staticClass:"span-text",class:{
    'top_text_8': _vm.hoveredIndex === index || _vm.selectedIndex === index,
    'top_text_7': _vm.hoveredIndex !== index && _vm.selectedIndex !== index
  },on:{"mouseover":function($event){return _vm.handleMouseOver(index)},"mouseleave":function($event){return _vm.handleMouseLeave(index)},"click":function($event){return _vm.handlePrimaryClick(index)}}},[_vm._v(" "+_vm._s(text)+" ")])}),(_vm.showSubMenu)?_c('div',{staticClass:"submenu flex-col",style:(_vm.submenuStyle),on:{"mouseenter":_vm.handleSubmenuMouseEnter,"mouseleave":_vm.handleSubmenuMouseLeave}},_vm._l((_vm.subMenus),function(submenu,subIndex){return _c('span',{key:subIndex,class:{
      'submenu_text_selected': _vm.hoveredSubIndex === subIndex || _vm.selectedSubIndex === subIndex,
      'submenu_text_no_select': _vm.hoveredSubIndex !== subIndex && _vm.selectedSubIndex !== subIndex
    },on:{"click":function($event){return _vm.navigateToSubMenu(subIndex)},"mouseover":function($event){_vm.hoveredSubIndex = subIndex},"mouseleave":function($event){_vm.hoveredSubIndex = -1}}},[_vm._v(" "+_vm._s(submenu)+" ")])}),0):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }