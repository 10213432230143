<template>
  <div class="header_box flex-row">
    <div class="block_1 flex-col"></div>
    <span class="top_text_1">鳞鲤科技</span>
    <span v-for="(text, index) in texts" :key="index" :class="{
      'top_text_8': hoveredIndex === index || selectedIndex === index,
      'top_text_7': hoveredIndex !== index && selectedIndex !== index
    }" @mouseover="handleMouseOver(index)" @mouseleave="handleMouseLeave(index)" @click="handlePrimaryClick(index)"
      class="span-text" :ref="'menu-' + index">
      {{ text }}
    </span>

    <!-- 二级菜单显示在 "产品中心" 下方 -->
    <div v-if="showSubMenu" class="submenu flex-col" :style="submenuStyle" @mouseenter="handleSubmenuMouseEnter"
      @mouseleave="handleSubmenuMouseLeave">
      <span v-for="(submenu, subIndex) in subMenus" :key="subIndex" :class="{
        'submenu_text_selected': hoveredSubIndex === subIndex || selectedSubIndex === subIndex,
        'submenu_text_no_select': hoveredSubIndex !== subIndex && selectedSubIndex !== subIndex
      }" @click="navigateToSubMenu(subIndex)" @mouseover="hoveredSubIndex = subIndex"
        @mouseleave="hoveredSubIndex = -1">
        {{ submenu }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: '麟鲤科技',
    meta: [
      { name: 'keywords', content: '麟鲤科技' },
      { name: 'description', content: '麟鲤科技' }
    ]
  },
  data() {
    return {
      hoveredIndex: -1, // 当前悬停的主菜单索引
      selectedIndex: this.initialSelectedIndex,
      hoveredSubIndex: -1, // 当前悬停的二级菜单索引
      selectedSubIndex: -1, // 当前选中的二级菜单索引
      showSubMenu: false, // 控制二级菜单的显示
      submenuHovered: false, // 控制是否悬停在二级菜单上
      submenuHideTimeout: null, // 用于保存隐藏二级菜单的延迟定时器
      texts: ["首页", "行业应用", "产品中心", "成功案例", "免费定制方案", "关于我们", "新闻动态"],
      routes: ["/", "", "", "", "/solution", "/about", "/news"],
      subMenus: [
        "BIM+数字孪生", "无人机", "麒麟视频大模型平台", "企业级大模型", "AI智能审图"
      ],
      subMenuRoutes: [
        "/products/bim", "/products/drone", "/products/video_model",
        "/products/enterprisetran_model", "/products/ai_review"
      ],
      submenuStyle: {}
    };
  },
  methods: {
    handleMouseOver(index) {
      clearTimeout(this.submenuHideTimeout); // 清除之前的定时器，确保不会过早隐藏
      this.hoveredIndex = index;
      if (index === 2) { // 如果是 "产品中心"
        const targetElement = this.$refs['menu-' + index][0];
        const rect = targetElement.getBoundingClientRect();
        this.submenuStyle = {
          top: `${rect.bottom + 5}px`,
          left: `${rect.left + window.scrollX}px`
        };
        this.showSubMenu = true; // 显示二级菜单
      }
    },
    handleMouseLeave(index) {
      if (index === 2) {
        this.submenuHideTimeout = setTimeout(() => {
          if (!this.submenuHovered) {
            this.showSubMenu = false; // 延迟隐藏二级菜单
          }
        }, 100); // 延迟 300ms 隐藏菜单
      }
      this.hoveredIndex = -1;
    },
    handleSubmenuMouseEnter() {
      clearTimeout(this.submenuHideTimeout); // 鼠标进入二级菜单时，取消隐藏定时器
      this.submenuHovered = true;
    },
    handleSubmenuMouseLeave() {
      this.submenuHovered = false;
      this.submenuHideTimeout = setTimeout(() => {
        if (!this.submenuHovered) {
          this.showSubMenu = false; // 鼠标离开二级菜单后，延迟隐藏
        }
      }, 100); // 延迟 300ms 隐藏菜单
    },
    handlePrimaryClick(index) {
      this.selectedIndex = index;
      if (index !== 2) {
        this.showSubMenu = false;
        this.$router.push(this.routes[index]); // 跳转到其他主菜单页面
      }
    },
    navigateToSubMenu(subIndex) {
      this.selectedSubIndex = subIndex; // 更新选中的二级菜单索引
      this.$router.push(this.subMenuRoutes[subIndex]); // 跳转到二级菜单的页面
    }
  }
};
</script>





<style scoped lang="css" src="../assets/header.css" />